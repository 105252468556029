module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-180326495-1","head":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TampaDD","short_name":"TampaDD","start_url":"/","background_color":"#ffffff","theme_color":"#E1138","display":"standalone","icon":"static/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b90f345adb570e5623dfb7e8a9bf101f"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/gatsby-theme-seagull/src/utils/typography.js"},
    },{
      plugin: require('../../gatsby-theme-seagull/gatsby-browser.js'),
      options: {"plugins":[],"sanityProjectId":"phsevku4","sanityDataset":"production","sanityGraphqlTag":"default","siteName":"Seagull Theme Base","homePath":"/"},
    }]
